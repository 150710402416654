@font-face {
  font-family: "MaisonNeue";
  src: url("../MaisonNeue-Light.otf");
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}
@font-face {
  font-family: "MaisonNeue";
  src: url("../MaisonNeue-Medium.otf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "MaisonNeue";
  src: url("../MaisonNeue-Bold.otf");
  font-style: bold;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "MaisonNeue";
  src: url("../MaisonNeue-ExtraBold.otf");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}
@font-face {
  font-family: "MaisonNeueExtended";
  src: url("../MaisonNeueExtended-Light.otf");
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}
@font-face {
  font-family: "MaisonNeueExtended";
  src: url("../MaisonNeueExtended-Medium.otf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "MaisonNeueExtended";
  src: url("../MaisonNeueExtended-Demi.otf");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "MaisonNeueExtended";
  src: url("../MaisonNeueExtended-Bold.otf");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}
@font-face {
  font-family: "MaisonNeueBook";
  src: url("../MaisonNeue-Book.otf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "MaisonNeueExtendedBook";
  src: url("../MaisonNeueExtended-Book.otf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
