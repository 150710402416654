@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700');

@font-face {
  font-family: "Roboto Slab";
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Roboto Slab";
  font-weight: 400;
  font-style: normal;
}
