@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;1,400');

@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
}