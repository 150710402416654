@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700');

@font-face {
  font-family: "Raleway";
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  font-weight: 400;
  font-style: normal;
}
